import './PostCreation.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {useState} from "react";
import {addDoc, collection} from "firebase/firestore";
import { signInWithEmailAndPassword } from "firebase/auth";
import {db, auth} from "../../firebase";

function PostCreation() {
    const [value, setValue] = useState('');

    const logIn = async () => {
        if (auth.currentUser) return;
        const email = "dospacite@protonmail.com";
        const password = document.getElementById("post-key").value;
        try {
            return await signInWithEmailAndPassword(auth, email, password);
        } catch (e) {
            console.error(e);
        }
    }

    const createPost = async () => {
        await logIn();
        await addDoc(collection(db, "documents"), {
            title: document.getElementById("post-title").value,
            author: document.getElementById("post-author").value,
            content: value
        });
    }

    return (
        <div className="post-creation">
            <span className="post-creation-title">
                Create a Post
            </span>
            <input id="post-title" className="post-creation-input" type="text" placeholder="Title" />
            <input id="post-author" className="post-creation-input" type="text" placeholder="Author" />
            <ReactQuill theme="snow" value={value} onChange={setValue} />
            <input id="post-key" className="post-creation-input" type="text" placeholder="Secret Key" />
            <button onClick={createPost} className="post-creation-button">Submit</button>
        </div>
    );
}

export default PostCreation;
