// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCsSi-toEZcXSBfzSsYicT1CdU02sCVFm0",
    authDomain: "futureflowsocial-653b1.firebaseapp.com",
    projectId: "futureflowsocial-653b1",
    storageBucket: "futureflowsocial-653b1.appspot.com",
    messagingSenderId: "1006452507628",
    appId: "1:1006452507628:web:edaf74594c85ee4b1a36c9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

export {db, auth};