import './Magazine.css';
import PostGroup from "../Post/PostGroup";

function Magazine() {
    return (
        <div className="magazine">
            <PostGroup />
        </div>
    );
}

export default Magazine;
