import React, {useEffect, useState} from "react";
import {collection, getDocs} from "firebase/firestore";
import {db} from "../../firebase";
import Post from "./Post";

function PostGroup() {
    const [posts, setPosts] = useState([]);

    const fetchPost = async () => {
        let docs = await getDocs(collection(db, "documents"));
        let data = [];
        docs.docs.forEach((doc) => {
            let docData = doc.data();
            docData.id = doc.id;
            data.push(docData);
        });
        return data;
    }

    useEffect(() => {
        fetchPost().then((data) => {
            setPosts(data);
        });
    }, []);

    return (
        <>
            {posts.map((post) => <Post postInfo={post}/>)}
        </>
    );
}

export default PostGroup;