function Post(props) {
    if (props === undefined || props.postInfo === undefined) {
        return (
            <div>
                <h1>No posts available</h1>
            </div>
        );
    }
    return (
        <div>
            <h2>{props.postInfo.title}</h2>
            <h4>{props.postInfo.author}</h4>
            <p dangerouslySetInnerHTML={{__html: props.postInfo.content}}></p>
        </div>
    );
}

export default Post;