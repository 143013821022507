import './Navbar.css';

function Navbar() {
    return (
        <nav>
            <img src="images/logo.webp" alt="" className="logo-navbar"/>
            <div className="spacer"></div>
            <div className="spacer align-end">
                <a href="#home" className="link-navbar">Home</a>
                <a href="#corporate" className="link-navbar">Corporate</a>
                <a href="#organizations" className="link-navbar">Organizations</a>
                <a href="#flowMagazine" className="link-navbar">Flow Magazine</a>
                <a href="#contact" className="link-navbar">Contact</a>
            </div>
        </nav>
    )
}

export default Navbar;