import './Hero.css';

function Hero() {
    return (
        <div className="fh hero">
            <div className="spacer col hero-text-column">
                <span className="hero-title">
                    Empowering the Youth of Tomorrow
                </span>
                <span className="hero-subtitle">
                    Future Flow Social initiates a transformative journey through diverse experiences, adventurous endeavors, and impactful community initiatives, guiding you, the current, toward a brighter future. Whether as a team member or participant in our events, our attendees will undoubtedly broaden their horizons.
                </span>
                <button className="hero-button">
                    Learn More
                </button>
            </div>
            <div className="spacer col hero-decoration-column">
                <div className="social-media-row">
                    <a href="" className="social-media-icon">
                        <svg width="800" height="800" viewBox="0 0 800 800" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M125 175L100 200V600L125 625H675L700 600V200L675 175H125ZM150 256.517V575H650V256.508L399.997 483.787L150 256.517ZM610.33 225H189.662L399.997 416.213L610.33 225Z" fill="#080341"/>
                        </svg>
                    </a>
                    <a href="" className="social-media-icon">
                        <svg width="800" height="800" viewBox="0 0 800 800" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M216.667 266.667C244.281 266.667 266.667 244.281 266.667 216.667C266.667 189.052 244.281 166.667 216.667 166.667C189.052 166.667 166.667 189.052 166.667 216.667C166.667 244.281 189.052 266.667 216.667 266.667Z" fill="#0F0F0F"/>
                            <path d="M166.667 333.333C166.667 314.924 181.591 300 200 300H233.333C251.743 300 266.667 314.924 266.667 333.333V600C266.667 618.41 251.743 633.333 233.333 633.333H200C181.591 633.333 166.667 618.41 166.667 600V333.333Z" fill="#0F0F0F"/>
                            <path d="M366.667 633.333H400C418.41 633.333 433.333 618.41 433.333 600V450C433.333 400 533.333 366.667 533.333 433.333V600.013C533.333 618.423 548.257 633.333 566.667 633.333H600C618.41 633.333 633.333 618.41 633.333 600V400C633.333 333.333 583.333 300 516.667 300C450 300 433.333 350 433.333 350V333.333C433.333 314.924 418.41 300 400 300H366.667C348.257 300 333.333 314.924 333.333 333.333V600C333.333 618.41 348.257 633.333 366.667 633.333Z" fill="#0F0F0F"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M666.667 33.3334C721.897 33.3334 766.667 78.105 766.667 133.333V666.667C766.667 721.897 721.897 766.667 666.667 766.667H133.333C78.105 766.667 33.3334 721.897 33.3334 666.667V133.333C33.3334 78.105 78.105 33.3334 133.333 33.3334H666.667ZM666.667 100C685.077 100 700 114.924 700 133.333V666.667C700 685.077 685.077 700 666.667 700H133.333C114.924 700 100 685.077 100 666.667V133.333C100 114.924 114.924 100 133.333 100H666.667Z" fill="#0F0F0F"/>
                        </svg>
                    </a>
                    <a href="" className="social-media-icon">
                        <svg width="800" height="800" viewBox="0 0 800 800" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M400 600C510.457 600 600 510.457 600 400C600 289.543 510.457 200 400 200C289.543 200 200 289.543 200 400C200 510.457 289.543 600 400 600ZM400 533.333C473.637 533.333 533.333 473.637 533.333 400C533.333 326.362 473.637 266.667 400 266.667C326.362 266.667 266.667 326.362 266.667 400C266.667 473.637 326.362 533.333 400 533.333Z" fill="#0F0F0F"/>
                            <path d="M600 166.667C581.59 166.667 566.667 181.591 566.667 200C566.667 218.409 581.59 233.333 600 233.333C618.41 233.333 633.333 218.409 633.333 200C633.333 181.591 618.41 166.667 600 166.667Z" fill="#0F0F0F"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M55.132 142.535C33.3334 185.318 33.3334 241.323 33.3334 353.333V446.667C33.3334 558.677 33.3334 614.683 55.132 657.463C74.3067 695.097 104.903 725.693 142.535 744.867C185.318 766.667 241.323 766.667 353.333 766.667H446.667C558.677 766.667 614.683 766.667 657.463 744.867C695.097 725.693 725.693 695.097 744.867 657.463C766.667 614.683 766.667 558.677 766.667 446.667V353.333C766.667 241.323 766.667 185.318 744.867 142.535C725.693 104.903 695.097 74.3067 657.463 55.132C614.683 33.3333 558.677 33.3333 446.667 33.3333H353.333C241.323 33.3333 185.318 33.3333 142.535 55.132C104.903 74.3067 74.3067 104.903 55.132 142.535ZM446.667 100H353.333C296.228 100 257.408 100.052 227.403 102.503C198.175 104.891 183.228 109.22 172.801 114.532C147.713 127.316 127.316 147.713 114.532 172.801C109.22 183.228 104.891 198.175 102.503 227.403C100.052 257.408 100 296.228 100 353.333V446.667C100 503.773 100.052 542.59 102.503 572.597C104.891 601.827 109.22 616.773 114.532 627.2C127.316 652.287 147.713 672.683 172.801 685.467C183.228 690.78 198.175 695.11 227.403 697.497C257.408 699.947 296.228 700 353.333 700H446.667C503.773 700 542.59 699.947 572.597 697.497C601.827 695.11 616.773 690.78 627.2 685.467C652.287 672.683 672.683 652.287 685.467 627.2C690.78 616.773 695.11 601.827 697.497 572.597C699.947 542.59 700 503.773 700 446.667V353.333C700 296.228 699.947 257.408 697.497 227.403C695.11 198.175 690.78 183.228 685.467 172.801C672.683 147.713 652.287 127.316 627.2 114.532C616.773 109.22 601.827 104.891 572.597 102.503C542.59 100.052 503.773 100 446.667 100Z" fill="#0F0F0F"/>
                        </svg>
                    </a>
                    <a href="" className="social-media-icon">
                        <svg width="560" height="559" viewBox="0 0 560 559" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.786057 0.960022L215.733 314.318L5.33867 558.04H51.9529L236.32 344.325L382.92 558.04H559.04L334.473 230.681L532.641 0.960022H486.17L313.857 200.588L176.906 0.960022H0.786057ZM56.4482 30.28H161.473L503.378 528.72H398.353L56.4482 30.28Z" fill="black"/>
                        </svg>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Hero;