import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Landing from "./components/Landing";
import PostCreation from "./components/PostCreation/PostCreation";
import Magazine from "./components/Magazine/Magazine";
import PostPage from "./components/Post/PostPage";

function App() {
  return (
      <BrowserRouter>
          <Routes>
              <Route index element={<Landing />} />
              <Route path="createPost" element={<PostCreation />} />
              <Route path="flowMagazine" element={<Magazine />} />
              <Route path="/post/:id" element={<PostPage />} />
          </Routes>
      </BrowserRouter>
  );
}

export default App;
