import {useParams} from "react-router-dom";
import {getDoc, doc} from "firebase/firestore";
import {db} from "../../firebase";
import {useState, useEffect} from "react";

function PostPage() {
    const { id } = useParams();
    const [postInfo, setPostInfo] = useState({});

    // Load Post
    const fetchPost = async () => {
        const docRef = doc(db, "documents", id);
        const docSnap = await getDoc(docRef);
        console.log(docSnap.data());
        return docSnap.data();
    }

    useEffect(() => {
        fetchPost().then((data) => {
            setPostInfo(data);
        });
    } , []);

    return (
        <div>
            <h2>{postInfo.title}</h2>
            <h4>{postInfo.author}</h4>
            <p dangerouslySetInnerHTML={{__html: postInfo.content}}></p>
        </div>
    );
}

export default PostPage;