import Navbar from './Navbar/Navbar';
import Hero from "./Hero/Hero";

function Landing() {
    return (
        <>
            <Navbar />
            <Hero />
        </>
    );
}

export default Landing;
